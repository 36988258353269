import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../layouts/Layout"
import { useStaticQuery, graphql, Link } from "gatsby"

const RisorsePage = (props) => {
  const data = useStaticQuery(graphql`
  {    
                
            
    pot: file(relativePath: { eq: "pot.svg" }) {
                    relativePath
                    publicURL
                }   
    risorse: file(relativePath: { eq: "risorse-main.svg" }) {
                    relativePath
                    publicURL
                }   

          allRisorseYaml {
                      nodes {
                          lang
                          risorse{
                          nome
                          url
                          }
                          jamstack{
                          nome
                          url
                          }
                          static_generators{
                          nome
                          url
                          }
                      }
                      }
  }
`)

  const datiArray = Object.values(data.allRisorseYaml.nodes[0])
  const datiArrayFiltrati = datiArray.filter((item) => {
    return Array.isArray(item) && item
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Risorse</title>
        <meta name="description" content="Vuoi approfondire gli argomenti che ruotano attorno al mondo del Jamstack?" />
      </Helmet>
      <Layout>
        <section className="maincontainer risorse-lista ">
          <img width="460" height="436" class="image-risorse" src={data.risorse.publicURL} />
          <h1>Risorse</h1>
          <p>Vuoi approfondire gli argomenti che ruotano attorno al mondo del Jamstack? <br />
            Ecco una lista in continuo aggiornamento
          </p>
          <h2>Analisi</h2>
          <ul>
            {datiArrayFiltrati[0].map((item) => {
           return <li> 
             <img src={data.pot.publicURL} />
             <a href={item.url}>{item.nome}</a> </li>
            })}
          </ul>
          <h2>Jamstack, che roba è?</h2>
          
          <ul>
            {datiArrayFiltrati[1].map((item) => {
           return <li> 
             <img src={data.pot.publicURL} />
             <a href={item.url}>{item.nome}</a> </li>
            })}
          </ul>
          <h2>Generatori statici di sitiweb</h2>
          <ul>
            {datiArrayFiltrati[2].map((item) => {
           return <li> 
             <img src={data.pot.publicURL} />
             <a href={item.url}>{item.nome}</a> </li>
            })}
          </ul>
        </section>


      </Layout>
    </>
  )
}

export default RisorsePage
