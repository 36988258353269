import * as React from "react"
import Navbar from "../components/navbar/Navbar"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet"
import '../assets/scss/global.scss'
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
import ScrollToTop from "react-scroll-to-top";

const Layout = ({ children, props }) => {

  return (
    <>
      <ScrollToTop smooth />
      <Navbar />

      {children}
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Ho capito"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#E20E57" }}
        buttonStyle={{ color: "#000", fontSize: "13px", background: "#fff" }}
        expires={150}
      >
        Questo sito utilizza cookies per migliorare l'esperienza di navigazione
        <Link to="/privacy">
          <span style={{ fontSize: "16px", paddingLeft: "10px", color: " white", textDecoration: "underline" }}>Leggi il documento di Privacy</span>
        </Link>
      </CookieConsent>
    </>
  )
}

export default Layout